import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4daf79a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-error" }
const _hoisted_2 = {
  key: 1,
  class: "h2 p-text-center"
}
const _hoisted_3 = {
  key: 2,
  class: "p-text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(["icon", _ctx.icon.icon]),
          style: _normalizeStyle(`color:var(--${_ctx.icon.color});`),
          "aria-hidden": "true"
        }, null, 6))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true),
    (_ctx.button)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 3,
          label: _ctx.button.label,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.buttonClick($event, _ctx.button)))
        }, null, 8, ["label"]))
      : _createCommentVNode("", true)
  ]))
}